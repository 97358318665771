<template>
    <div class="Profile-certificates" v-loading="loadingService">
        <div class="certificates">
            <div class="row">
                <div v-if="!certificates.length">
                    <div class="container">
                        {{ $t('profile.empty') }}
                    </div>
                </div>
                <div v-else class="certificates__certificate" v-for="certificate in certificates">
                    <div class="certificate-card" :class="{'certificate-card--pink': certificate.given_out === 2}">
                        <div>
                            <div class="certificate-card__title" v-if="certificate.given_out === 2">
                                {{$t('profile.certificates.winner-title')}}
                            </div>
                            <div class="certificate-card__title" v-else>
                                {{$t('profile.certificates.participant-title')}}
                            </div>
                            <div class="certificate-card__desc">
                                {{ certificate.comment }}
                            </div>
                            <div class="certificate-card__text" v-if="certificate.given_out === 1">
                                <span>{{$t('profile.certificates.issued-for')}}</span>
                                {{$t('profile.certificates.participation')}}
                            </div>
                            <div class="certificate-card__text" v-if="certificate.given_out === 2">
                                <span>{{$t('profile.certificates.issued-for')}}</span>
                                {{$t('profile.certificates.victory')}}
                            </div>
                            <div class="certificate-card__text" v-if="certificate.place">
                                <span>{{$t('profile.certificates.place-title')}}</span>

                                {{ certificate.place }} {{$t('profile.certificates.place-desc')}}
                            </div>
                            <div class="certificate-card__text" v-if="certificate.subject">
                                <span>{{$t('profile.certificates.subject')}}</span>
                                {{ certificate.subject }}
                            </div>
                        </div>
                        <div>
                            <div class="certificate-card__btn-block">
                                <button class="certificate-card__btn" @click="downloadCert(certificate.path)">
                                    <img src="/images/profile/download-icon.svg" width="17" height="17" alt="">
                                    <div>
                                        {{$t('profile.certificates.download')}}
                                    </div>
                                </button>
                                <div class="certificate-card__img">
                                    <img v-if="certificate.given_out === 1" src="/images/profile/certificate-purple.svg" width="40" height="40" alt="">
                                    <img v-if="certificate.given_out === 2" src="/images/profile/certificate-pink.svg" width="40" height="40" alt="">
                                </div>
                            </div>
                            <div class="certificate-card__date">
                                {{$t('profile.certificates.issued-on')}} {{ certificate.created_at }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-pagination
            v-if="total > perPage"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            last-number
            first-number
            @change="getCertificates"
            hide-goto-end-buttons
            align="center"
            next-text=">"
            prev-text="<">
        </b-pagination>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                loadingTimeout: false,
                loadingService: false,
                currentPage: 1,
                total: 0,
                perPage: 1,
                certificates: []
            }
        },
        mounted() {
            this.getCertificates(this.currentPage)
        },
        methods: {
            getCertificates(page) {
                this.loadingService = true;
                this.loadingTimeout = setTimeout(() => {
                    this.loadingService = false;
                }, 5000);
                this.$http.get(window.API_ROOT + `/api/achievement/certificates?page=${page}`)
                    .then((res) => {
                        this.certificates = res.body.data
                        this.total = res.body.total
                        this.perPage = res.body.perPage
                        this.loadingService = false
                        window.clearTimeout(this.loadingTimeout)
                    })
                    .catch(() => {
                        this.loadingService = false
                        window.clearTimeout(this.loadingTimeout)
                    })
            },
            downloadCert(path) {
                window.open(window.API_ROOT + `/api/achievement/get-certificate?path=${path}`, '_blank');
                // this.$http.get(window.API_ROOT + `/api/achievement/get-certificate?path=${path}`)
                //     .then((res) => {
                //     })
                //     .catch(() => {
                //     })
            }
        },
    }
</script>
<style lang="less" scoped>
.Profile-certificates {
    max-width: 100%;
    // margin: 0 auto;
}
.certificates .row:last-child {
    margin-top: 25px;
}
.certificate-card {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 24px 18px;
    font-family: 'Roboto', sans-serif;

    width: 100%;
    background: #ffffff url("/images/profile/bg-purple.svg") no-repeat bottom;
    background-size: contain;
    min-height: 371px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.certificate-card__title {
    color: #473F95;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 18px;
}
.certificate-card__desc {
    color: #A9A9A9;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 23px;
}
.certificate-card__text {
    color: #473F95;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
}
.certificate-card__text span {
    font-weight: bold;
}
.certificate-card__btn {
    color: #FFFFFF;
    background: #473F95;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius:  5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: none;
    font-size: 14px;
    line-height: 17px;
    padding: 8px 24px;

}
.certificate-card__btn img {
    margin-right: 10px;
}
.certificate-card__btn-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 38px;
}
.certificate-card__img {
    width: 75px;
    height: 75px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
}
.certificate-card__date {
    color: #473F95;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    margin-top: 29px;
}
.certificate-card--pink .certificate-card__title {
    color: #D23168;
}
.certificate-card--pink .certificate-card__text {
    color: #D23168;
}
.certificate-card--pink .certificate-card__btn {
    background: #D23168;
}
.certificate-card--pink .certificate-card__date {
    color: #D23168;
}
.certificate-card--pink .certificate-card__btn-block {
    padding-top: 12px;
}
.pagination {
    border: none;
    box-shadow: none;
    margin-bottom: 30px;
    padding-top: 50px;
}
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}
/deep/ .page-link:focus {
    box-shadow: none;
}
.certificates__certificate {
  width: 20%;
    margin: 1rem;
    position: relative;
}
@media (max-width: 1400px) {
  .certificates__certificate {
    width: 30%;
    margin: 1rem;
    position: relative;
  }
}
@media (max-width: 1204px) {
  .certificates__certificate {
    width: 46%;
    margin: 1rem;
    position: relative;
  }
}
@media (max-width: 1043px) {
  .certificates__certificate {
    width: 100%;
    margin: 1rem;
    position: relative;
  }
}
@media (max-width: 991px) {
    .Profile-certificates {
        max-width: none;
        margin: 0;
    }
   .certificate-card {
       margin: 0 auto;
   }
   .certificates__certificate {
       margin-bottom: 30px;
   }
   /deep/ .pagination {
       padding-top: 0;
       margin-bottom: 0;
   }
   /deep/ .pagination .page-item .page-link {
        font-size: 14px;
        line-height: 16px;
        min-width: 38px;
   }
}
</style>